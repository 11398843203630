/**
 * sessionStorage에 데이터를 저장합니다.
 * @param {string} key - 저장할 키
 * @param {any} value - 저장할 값 (객체 등은 자동으로 JSON.stringify 처리됨)
 */
export function setSessionData(key, value) {
  try {
    sessionStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error('sessionStorage 저장 에러:', error);
  }
}

/**
 * sessionStorage에서 데이터를 조회합니다.
 * @param {string} key - 조회할 키
 * @returns {any} 저장된 데이터 (없으면 null)
 */
export function getSessionData(key) {
  try {
    const item = sessionStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  } catch (error) {
    console.error('sessionStorage 조회 에러:', error);
    return null;
  }
}

/**
 * sessionStorage에서 특정 데이터를 삭제합니다.
 * @param {string} key - 삭제할 키
 */
export function removeSessionData(key) {
  try {
    sessionStorage.removeItem(key);
  } catch (error) {
    console.error('sessionStorage 삭제 에러:', error);
  }
}

/**
 * sessionStorage의 모든 데이터를 삭제합니다.
 */
export function clearSessionData() {
  try {
    sessionStorage.clear();
  } catch (error) {
    console.error('sessionStorage 전체 삭제 에러:', error);
  }
}
