import { useEffect, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import LeftBannerImage from '../../resources/images/left_banner.png'

export default function LeftBanner () {
  const [isMounted, setIsMounted] = useState(false)
  const [visible, setVisible] = useState(true)

  useEffect(() => {
    const hideUntil = localStorage.getItem('hideLeftBannerUntil')
    if (!hideUntil || new Date() > new Date(hideUntil)) {
      setIsMounted(true)
    }
  }, [])

  function closeBanner () {
    setVisible(false)
  }

  function hideForThreeDays () {
    const hideUntil = new Date()
    hideUntil.setDate(hideUntil.getDate() + 3) // 3일 추가
    // hideUntil.setMinutes(hideUntil.getMinutes() + 1) // 1분 뒤
    localStorage.setItem('hideLeftBannerUntil', hideUntil.toISOString())
    setVisible(false)
  }

  return (
    <AnimatePresence onExitComplete={() => setIsMounted(false)}>
      {isMounted && (
        <motion.a key="left-banner"
                  href="https://sblog.i-scream.co.kr/pretty56/16963"
                  target="_blank"
                  rel="noopener noreferrer"
                  initial={{ x: -300, opacity: 0 }}
                  animate={visible ? { x: 10, opacity: 1 } : { x: -300, opacity: 0 }}
                  exit={{ x: -300, opacity: 0 }}
                  transition={{ duration: 0.5 }}
                  className="fixed top-1/3 left-0 bg-white shadow-lg rounded-full w-[200px] h-[300px] z-[10]">
          <img src={LeftBannerImage} alt="LeftBanner" className={'absolute top-0 left-0 w-[200px] h-[300px] font-[pd-eb]'}/>
          <div className="absolute bottom-0 w-[200px] h-[30px] bg-[#000000] text-[#ffffff] rounded-b-[18px] flex justify-center items-center cursor-pointer select-none"
               onClick={(e) => {
                 e.preventDefault()
                 e.stopPropagation()
                 hideForThreeDays()
               }}>
            3일간 보지 않기
          </div>
          <div className="absolute top-[6px] right-[6px] text-[#ffffff] cursor-pointer select-none"
               onClick={(e) => {
                 e.preventDefault()
                 e.stopPropagation()
                 closeBanner()
               }}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={4} stroke="currentColor" className="size-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12"/>
            </svg>
          </div>
        </motion.a>
      )}
    </AnimatePresence>
  )
}
