import { ReactComponent as Logo } from './resources/images/arkmath_logo.svg';
import {useEffect} from "react";

const EmptyPage = () => {
    useEffect(() => {
        window.history.replaceState(null, "", "/"); // 주소창에서 경로를 숨김
    }, []);


    return (
    <div className={'w-full h-[100vh] flex justify-center items-center'}>
      <Logo/>
    </div>
  );
};

export default EmptyPage;
