import { useEffect } from 'react';

const useKeyPress = (key, action) => {
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.keyCode === key) {
        action();
      }
    };
    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [key, action]);
};

export default useKeyPress;
