import './App.css';
import { Route, Routes } from 'react-router-dom';
import QrCode from './features/lesson/QrCode';
import QrCodePage from './features/lesson/QrCodePage';
import EmptyPage from './EmptyPage';
import { Toaster } from 'react-hot-toast';
import LessonPage from './features/lesson/LessonPage';
import packageJson from '../package.json';

function App () {
  return (
    <div className="App">
      <div className={'fixed left-[10px] bottom-[10px] text-[12px]'}>v{packageJson.version}</div>
      <Toaster position="top-center" reverseOrder={false}/>
      <Routes>
        <Route path="/lesson/*" element={<LessonPage/>}></Route>
        <Route path="/qrcode" element={<QrCode/>}></Route>
        <Route path="/qrcode-page" element={<QrCodePage/>}></Route>
        <Route path="/empty" element={<EmptyPage/>}></Route>
        {/* 상단에 위치하는 라우트들의 규칙을 모두 확인하고, 일치하는 라우트가 없다면 마지막 라우트가 화면에 나타나게 됨. */}
        <Route path="*" element={<LessonPage/>}></Route>
      </Routes>
    </div>
  );
}

export default App;
