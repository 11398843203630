import { QRCodeCanvas } from 'qrcode.react';
import DraggableModal from '../../shared/components/DraggableModal';
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

const LoginQrCodeModal = ({ showLoginQrModal, setShowLoginQrModal, classDetails }) => {
  const contentRef = useRef(null);

  // 한 페이지에 표시할 카드 개수
  const cardsPerPage = 9;

  // 데이터를 페이지별로 나누기
  const paginatedStudents = [];
  for (let i = 0; i < classDetails?.students?.length; i += cardsPerPage) {
    paginatedStudents.push(classDetails.students.slice(i, i + cardsPerPage));
  }

  const handlePrint = useReactToPrint({
    // onBeforeGetContent: () => contentRef.current.classList.remove('hidden'),
    content: () => contentRef.current,
    // onAfterPrint: () => contentRef.current.classList.add('hidden'),
  });

  return (
    <DraggableModal show={showLoginQrModal} setShow={setShowLoginQrModal} title={'로그인 QR 인쇄 목록'} titleStyle={'text-[#50555F] text-[28px] font-[pd-b]'} headerHeight={65}>
      <div className={'w-[80vw] p-[20px]'}>
        <div className={'flex justify-center items-center'}>
          <div className="cursor-pointer text-[20px] font-[pd-b] text-[#ffffff] bg-[#7494FF] w-[136px] h-[44px] flex justify-center items-center rounded-[200px]" onClick={() => handlePrint()}>
            인쇄하기
          </div>
        </div>
        <div className={'mt-[20px]'}>
          {/*<div className={'grid grid-flow-row auto-rows-max gap-4 grid-cols-[repeat(auto-fill,minmax(200px,1fr))] place-items-start'}>*/}
          {/*  {classDetails?.students?.map((value, index) => (*/}
          {/*    <div key={index} className={'w-[200px] border px-[30px] py-[20px] rounded-[20px] mb-[40px]'}>*/}
          {/*      <div className={'flex justify-center font-[pd-sb] text-[20px]'}>AI 아크수학</div>*/}
          {/*      <div className={'flex justify-center mt-[20px] font-[pd-sb] text-[18px]'}>{classDetails?.name}</div>*/}
          {/*      <div className={'flex justify-center'}>*/}
          {/*        <div>*/}
          {/*          <div className={'w-[100px]'}>번호&nbsp;&nbsp;&nbsp;{value.number}</div>*/}
          {/*          <div className={'w-[100px] truncate'}>이름&nbsp;&nbsp;&nbsp;{value.name}</div>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*      <div className={'flex justify-center'}>*/}
          {/*        <QRCodeCanvas value={`${process.env.REACT_APP_WEB_URL}/qrcode?type=login&studentId=${value.studentId}`} size={128} bgColor="#ffffff" fgColor="#000000" className={'mt-[20px]'}/>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  ))}*/}
          {/*</div>*/}
          <div
            className="flex justify-center items-center"
            ref={contentRef}
          >
            <div>
              {paginatedStudents.map((page, pageIndex) => (
                <div key={pageIndex} className="print-container">
                  {page.map((value, index) => (
                    <div key={index} className="print-card">
                      <div className="flex justify-center font-[pd-sb] text-[20px]">AI 아크수학</div>
                      <div className="flex justify-center mt-[20px] font-[pd-sb] text-[18px] w-[170px] truncate">{classDetails?.name}</div>
                      <div className="flex justify-center">
                        <div>
                          <div className={'w-[100px]'}>번호&nbsp;&nbsp;&nbsp;{value.number}</div>
                          <div className={'w-[100px] truncate'}>이름&nbsp;&nbsp;&nbsp;{value.name}</div>
                        </div>
                      </div>
                      <div className="flex justify-center">
                        <QRCodeCanvas
                          value={`${process.env.REACT_APP_WEB_URL}/qrcode?type=login&studentId=${value.studentId}`}
                          size={128}
                          bgColor="#ffffff"
                          fgColor="#000000"
                          className="mt-[20px]"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </DraggableModal>
  );
};

export default LoginQrCodeModal;
