import Content from './Content';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getInquiries, getTeacherDetails, updateTeacher } from '../../network/Endpoint';
import { getSessionData, setSessionData } from '../../utils/sessionUtils';
import TermsModal from './TermsModal';
import { checkToast } from '../../shared/components/Toast';
import useKeyPress from '../../hooks/useKeyPress';
import InquiryCreationModal from '../inquiry/InquiryCreationModal';
import { ReactComponent as InquiryIcon } from '../../resources/images/inquiry.svg';
import InquiryDetailModal from '../inquiry/InquiryDetailModal';
import LeftBanner from './LeftBanner'

const LessonPage = () => {
  const termsOfServiceConsentText = `제1장 총칙
제1조 목적
본 서비스 약관(이하 ‘본 약관’)은 이용자(이하 ‘회원’)가 ㈜아이스크림미디어와 ㈜매쓰마스터 (이하 ‘회사’)에서 공동 개발 및 운영하여 제공하는 AI아크수학 서비스 (이하 ‘서비스’)를 이용함에 있어 필요한 권리, 의무 및 책임 사항, 이용조건 및 절차 등 기본적인 사항을 규정함을 목적으로 합니다.
제2조 정의
본 약관에서 사용하는 용어의 정의는 다음과 같습니다.
1.\t회사: AI아크수학 서비스를 공동 개발 및 운영하는 ㈜아이스크림미디어와 ㈜매쓰마스터를 말합니다.
2.\t서비스: AI아크수학 웹/앱을 통해 제공되는 모든 학습 콘텐츠 및 부가 기능을 의미합니다.
3.\t회원: 본 약관에 따라 회사와 이용계약을 체결하고 회사가 제공하는 서비스를 이용하는 고객을 의미하며 선생님 회원과 학생 회원, AI아크수학 회원을 통칭합니다.
① 선생님 회원: 아이스크림에서 교사 계정으로 가입하여 인증 받은 이용자를 의미합니다.
② 학생 회원: 선생님이 아이스크림 클래스에서 임시 학생 계정 생성에 법정 대리인 동의 후 생성한 이용자로, 클래스 구성원을 의미합니다.
③ AI아크수학 회원: AI아크수학 서비스에 직접 가입한 이용자 또는 학생 회원이 학부모(법정 대리인) 동의를 거쳐 가입한 이용자를 의미합니다.
④ 유료 회원: AI아크수학 내 유료 서비스를 이용 중인 회원을 의미합니다. 회사는 유료 회원과 무료 회원에게 제공하는 서비스에 차별을 두어 제공할 수 있습니다.
4.\t클래스: 배움을 목적으로 본 서비스 내에서 개설할 수 있는 온라인 학습공간을 의미합니다.
5.\t클래스코드: 특정 클래스에 참여할 수 있도록 만들어진 코드를 의미합니다.
6.\t클래스 관리자: 아이스크림 통합회원으로 가입한 회원으로 본 약관에 따라 회사가 제공하는 서비스를 이용하는 고객을 의미합니다.
7.\t클래스 구성원: 클래스 관리자가 직접 발급해준 계정을 받아 서비스를 이용하는 회원으로 클래스 관리자가 개설한 클래스에 참여할 수 있도록 만들어진 임시 로그인 코드를 통해 서비스 사용이 가능합니다.
8.\t아이디: 회원의 서비스 이용을 위해 부여되는 고유한 식별 정보를 의미합니다.
9.\t비밀번호: 회원의 계정보호를 위해 설정한 문자와 숫자의 조합을 의미합니다.
10.\t유료서비스: 회사가 제공하는 서비스 중 회원이 회사에 일정 금액을 지불하거나, 회원이 회사 또는 제3자와의 거래 내지 약정 조건을 수락하는 경우에 이용할 수 있는 회사의 서비스를 의미합니다.
11.\t결제: 회사가 제공하는 유료서비스를 이용하기 위하여 각종 지불수단을 통하여 일정 금액을 회사 또는 회사가 정한 제3자에게 지불하는 것을 의미합니다.
12.\t이용권: AI아크수학의 유료서비스를 이용할 권리가 있는 코드를 의미합니다. 
13.\t오늘의 학습: 설정한 목표 학습량대로 학습하며, 한 단원을 3회씩 반복하는 학습 콘텐츠입니다.
14.\t일프로 도전: 제한 시간 동안 정확하게 문제를 풀 수 있는 학습 콘텐츠를 의미합니다.
15.\t학습게임: 다양한 게임으로 연산 학습을 할 수 있는 콘텐츠를 의미합니다.
16.\t학교 학습: 선생님이 수업시간에 공유한 학습 콘텐츠를 의미합니다.
17.\t숙제: 방과 후 시간 학습을 위해 선생님이 클래스 구성원에게 내준 학습 콘텐츠를 의미합니다.
18.\t다이아: 학습 및 출석 등에 따라 부여되는 보상을 의미합니다. 
19.\t상점: 획득한 다이아로 아바타를 꾸밀 수 있는 아이템을 구매하는 공간을 의미합니다.
20.\t아바타: 자신이 선택하여 다양한 아이템을 꾸밀 수 있는 캐릭터를 의미합니다.
21.\t아바타 콘테스트: 한 달에 한 번 투표로 우승자를 뽑는 콘테스트입니다.
제3조 약관의 게시와 개정
1. 본 약관의 내용은 개별 서비스 또는 서비스 초기 화면에 게시하여 회원이 확인할 수 있도록 하고 본 약관에 동의한 회원 모두에게 그 효력이 발생합니다.
2. 회사는 관련 법령을 위배하지 않는 범위 내에서 본 약관을 개정할 수 있으며, 변경되는 경우 회사는 변경 사항을 시행 일자 15일 전부터 회원에게 공지합니다. 단, 회원에게 불리한 내용으로 변경할 경우에는 그 시행 일자 30일 전에 변경 사항을 공지 또는 통지합니다.
3. 회원이 변경된 약관에 동의하지 않는 경우 서비스 이용을 중단하고 탈퇴할 수 있습니다.
4. 본 약관에 명시되지 않은 사항에 대해서는 관계 법령의 규정을 따르며, 그러지 아니한 경우에는 회사가 제공하는 개별 서비스 이용약관 및 운영정책에 따릅니다.
제4조 회원에 대한 통지
1. 회사는 본 약관에 별도의 규정이 없는 한 회원이 가입 시 제공한 연락처로 이메일, 문자 메시지, 카카오톡 메시지나 서비스 내 팝업 화면 등으로 통지할 수 있습니다.
2. 전체 회원에게 영향을 미치는 사항은 7일 이상 회사가 운영하는 서비스 내 공지사항 게시함으로써 본 조 1항의 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항은 제1항의 방법으로 통지합니다.
3. 회원이 연락처를 정확히 기재하지 않거나 변경 후 수정하지 않는 등으로 인해 통지받지 못한 경우, 그에 대한 책임은 회원에게 있습니다.
제2장 서비스 신청
제5조 이용 신청
1. 이용신청은 회원이 되고자 하는 사용자가 본 약관의 내용을 숙지하고 동의를 한 다음 회원가입을 신청하고 회사가 승낙함으로써 체결됩니다.
2. 회원이 되고자 하는 이용자는 다음 각호의 방법에 의하여 회원가입을 할 수 있습니다.
① 선생님 회원은 아이스크림s에서 선생님계정으로 가입한 회원으로 이용 약관에 따라 회사가 제공하는 선생님 전용 서비스를 이용할 수 있습니다.
② 학생 회원은 선생님회원이 서비스를 이용하기 위해 아이스크림클래스에 법정대리인 동의를 거쳐 임의의 정보로 등록한 경우 이용할 수 있습니다.
③ AI아크수학 회원은 가입 시 학부모(법정대리인) 동의를 거쳐 가입한 회원으로 가입할 수 있습니다. 만약 기존 학생 회원의 계정이 있는 경우 AI아크수학 계정과 연결하여 서비스를 이용할 수 있습니다.
3. 회사는 제1항에 따라 회원이 온라인 회원가입 신청 양식에 기재하는 모든 회원 정보를 실제 데이터인 것으로 간주하며, 허위 정보 입력 시 법적 보호를 받을 수 없고 본 약관의 관련 규정에 따라 서비스 사용에 제한을 받을 수 있습니다.
4. 회사는 기술 및 설비의 미보유로 인하여 서비스 제공이 불가능한 경우 승낙을 유보할 수 있습니다.
5. 회사가 본 조항에 따라 이용신청을 승낙하지 않거나 유보하는 경우 회사는 원칙적으로 이를 회원에게 알립니다.
6. 본 이용계약의 성립은 회사의 승낙이 회원에게 도달한 시점에 성립되며, 회사는 정책에 따라 역할별로 구분하여 이용시간, 이용횟수, 서비스 메뉴 등의 이용에 차등을 둘 수 있습니다.

제6조 이용계약의 성립
1. 이용계약은 본 이용약관 내용을 확인하고 서비스를 이용할 것에 대한 이용신청자의 동의와 회사의 승낙에 의하여 성립합니다.
2. 이용계약 체결은 개인 혹은 단체 회원 모두 가능합니다. 다만, 회원 당 하나의 아이디만 받아 사용할 수 있습니다.
3. 회사의 승낙의 의사표시에서는 이용신청자의 신청에 대한 확인 및 서비스 제공 가능여부, 이용신청의 정정, 취소 등에 관한 정보를 포함합니다.
제 7조 이용계약의 유보 및 거절
1. 회사는 다음에 해당하는 경우 이용계약의 승낙을 유보할 수 있습니다.
① 서비스 설비에 여유가 없는 경우
② 서비스를 제공하기에는 기술적으로 문제가 있다고 판단되는 경우
③ 기타 회사가 필요하다고 인정되는 경우
2. 회사는 다음에 해당하는 경우 이용계약의 승낙을 거절할 수 있습니다.
① 타인의 명의를 도용하여 이용신청을 하였을 경우
② 이용신청서의 내용을 허위로 기재하였거나 허위서류를 제출하였을 경우
③ 사회의 안녕, 질서 또는 미풍양속을 해칠 목적으로 가입하였을 경우
④ 회사의 사전 허락 없이 자동화된 수단(매크로 프로그램, 로봇 등)을 이용하여 회원으로 가입을 시도 또는 가입하였을 경우
⑤ 기타 회사가 필요하다가 인정되는 경우
제8조 회원정보의 변경 및 탈퇴
1. 회원은 서비스 화면 내의 개인정보 페이지(아이스크림미디어 통합회원 정보수정)를 통하여 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다.
2. 회원이 개인정보를 변경하지 않아 발생하는 불이익에 대하여 회사는 고의 또는 중대한 과실이 없는 한 책임을 부담하지 않습니다.
3. 회원은 탈퇴하고자 할 경우 서비스 화면 내 회원탈퇴(아이스크림미디어 통합회원 탈퇴안내) 또는 고객센터에 전화하여 본 서비스의 이용계약을 해지할 수 있습니다. 회사에 탈퇴신청을 할 경우 회사는 지체 없이 탈퇴 처리하고 회원에게 탈퇴사실을 회신합니다.
4. 회원은 탈퇴 후 본인의 이용 내역 확인이 불가합니다.
5. 회사는 제4항을 안내하였음에도 불구하고 회원이 탈퇴한 경우 이에 대한 책임을 부담하지 않습니다.
제 9조 개인정보보호 및 관리
1. 회사는 서비스 제공을 위해 필요한 최소한의 개인정보를 수집할 수 있습니다.
2. 회사가 회원의 개인 식별이 가능한 개인정보를 수집하는 때에는 회원의 동의를 받습니다.
3. 회사는 회원이 회원가입 시 제공한 정보와 제1항에 의하여 수집한 정보를 회원의 동의 없이 목적 외로 이용하거나 제3자에게 제공할 수 없습니다. 다만, 다음의 경우에는 예외로 합니다.
① 법령에 의하여 필요한 불가피한 사유가 있는 경우
② 도용 방지를 위하여 본인확인에 필요한 경우
4. 회원은 언제든지 제2항과 제3항의 동의를 철회할 수 있으나 서비스 제공에 필요한 정보의 수집 또는 제공 동의를 철회할 경우 서비스의 제공이 제한될 수 있습니다.
5. 회사는 관계 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 대해서는 개인정보 보호법 등 관련 법령 및 회사의 개인정보 처리방침이 적용됩니다.
6. 회사는 회사의 귀책 없이 회원의 귀책 사유로 인하여 회원의 정보가 노출된 경우 이에 대해서는 책임지지 않습니다.
7. 회사는 회원의 개인정보를 안전하게 처리하기 위하여 노력합니다. 기타 자세한 사항은 ‘개인정보 처리방침’을 참고해주시기 바랍니다.
제 10조 회원의 아이디 및 비밀번호에 대한 의무
1. 아이디와 비밀번호에 관한 관리 책임은 회원 본인에게 있습니다.
2. 회원은 자신의 아이디 및 비밀번호를 제3자에게 제공, 공개하거나 제3자가 이용하도록 해서는 안 됩니다.
3. 회원이 자신의 아이디 및 비밀번호의 유출 또는 제3자 사용에 대해 인지하고도 비밀번호를 변경하지 않은 경우 또는 이와 같은 사정을 회사에 통지하지 않거나 회사의 조치에 응하지 않은 경우 등 회원이 본 조의 의무를 준수하지 않아 발생하는 모든 손실이나 손해 등 불이익에 대한 책임은 회원에게 있습니다. 단, 회사에 고의 또는 중과실이 있는 경우 그러하지 아니합니다.

제3장 서비스 제공 및 이용
제 11조 서비스의 이용
1. 회원은 가입 신청 시 회원이 지정한 회원 아이디와 비밀번호를 통해 서비스에 로그인하여 서비스를 이용할 수 있습니다.
2. 선생님 회원은 서비스 이용 중 필요에 따라 아이디를 제외한 비밀번호와 개인정보를 ‘아이스크림미디어 통합회원 정보수정’ 페이지를 통하여 직접 변경할 수 있습니다.
3. 회사는 회원의 이용 신청을 승낙한 때부터 서비스를 개시합니다.
4. 정기점검 및 기술적 사유로 인해 서비스를 일시적으로 중단할 경우에는 사이트에 공시하거나 회원에게 이를 통지합니다.
제 12조 다이아 정책
1. 다이아란 서비스에서 학습 콘텐츠 완료 및 출석 여부에 따라 획득할 수 있는 서비스 내 학습보상체계의 일종입니다.
2. 다이아는 서비스가 정하는 기준과 절차에 의해 적립됩니다.
3. 서비스에서 회원을 탈퇴하거나 아이스크림클래스 회원정보가 변경되는 경우 적립된 다이아도 자동 소멸됩니다. 다이아는 서비스 운영정책에 의해 가감되는 가상의 데이터로서 화폐가 아니므로 서비스는 이를 현금 또는 현물로 교환하거나 반환하여야 할 의무를 부담하지 않습니다.
4. 다이아는 환불 및 타인에게 양도되지 않습니다. AI아크수학 계정에 연결하지 않은 학생회원은 클래스가 말소된 경우 적립한 다이아는 자동으로 소멸됩니다. 
5. 서비스를 종료하고자 할 경우 본 약관 규정된 통지방법을 준용하여 회원에게 공지하며, 사용하지 아니한 다이아는 자동 소멸됩니다.
6. 회사는 제공되는 서비스의 일부 또는 전부를 회사의 정책 및 운영상 상당한 이유가 있는 경우 다이아 발급 및 사용을 제한할 수 있으며, 이에 대하여 별도의 보상을 하지 않습니다.
7. 이 약관에 규정되지 않은 사항에 대해서는 관련법령 또는 상관례에 따릅니다.

제13조 서비스의 제공 및 변경, 중단
1. 회사는 연중무휴, 1일 24시간 서비스 제공을 원칙으로 합니다. 
2. 회사는 각 브랜드 사이트를 포함하여 회사가 운영하는 사이트를 통합하여 운영하며, 회원은 동일 아이디와 비밀번호로 각 브랜드 사이트에 로그인하여 이용할 수 있습니다. 단, 학생 회원과 AI아크수학 회원은 포함되지 않습니다.
3. 회사는 다음 각호와 같은 서비스를 제공합니다.
① 클래스 개설 및 이용에 수반되는 서비스
② 아이스크림미디어 AI러닝 및 이용에 수반되는 서비스
③ 전자상거래 서비스(통신판매중개 서비스 포함) 및 이에 수반되는 기타 서비스
4. 회사는 회원의 가입신청을 승낙한 때부터 서비스를 개시합니다. 단, 일부 서비스의 경우 지정된 일자부터 서비스를 개시하며, 유료 서비스의 경우 결제가 완료된 후 서비스를 제공합니다.
5. 회사는 회원에게 서비스 체험 등의 목적으로 무료 이용 기간을 제공할 수 있습니다.
6. 회사는 서비스를 일정 범위로 분할하여 범위별로 이용 가능 시간을 별도로 지정할 수 있습니다. 다만 이러한 경우 그 내용을 사전에 공지합니다.
7. 회사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 서비스의 제공을 일시적으로 중단할 수 있습니다. 
8. 회사는 상당한 이유가 있는 경우 운영상, 기술상의 필요에 따라 제공하고 있는 서비스의 일부 또는 전체를 변경할 수 있습니다.
9. 회사는 콘텐츠 서비스의 내용, 이용 방법, 이용 시간을 변경할 경우에는 변경 사유, 변경될 콘텐츠 서비스의 내용 및 제공 일자 등을 그 변경 전 7일 이상 공지사항을 통해 게시합니다.
10. 회사는 서비스의 제공에 필요한 경우 정기점검을 할 수 있으며, 정기점검시간은 서비스 제공화면에 공지한 바에 따릅니다.
11.회사는 제7항 및 제8항에 따라 서비스의 일부 또는 전체를 변경하는 경우 제4조에 정한 방법으로 회원에게 통지합니다. 다만, 회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.
12. 회사는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책 및 운영상 상당한 이유가 있는 경우 수정, 중단 변경할 수 있으며, 이에 대하여 별도의 보상을 하지 않습니다. 단, 유료 서비스에 대해 운영의 필요상 수정 또는 중단이 발생하는 경우 관련법에 따라 회원에게 통지하고, 환불 규정에 따라 환불이 진행됩니다.
제14조 회사의 의무
1. 회사는 관련 법령 및 본 약관에 반하는 행위를 하지 않으며, 지속적이고 안정적인 서비스를 제공하기 위하여 최선을 다하여 노력합니다.
2. 회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보보호를 위해 보안 시스템을 갖추어야 하며, 개인정보 처리방침을 공시하고 준수합니다.
3. 회사는 회원으로부터 제기되는 의견이나 불만이 객관적으로 정당하다고 인정될 경우에는 회원의 불만을 해결하기 위해 노력합니다.
4. 서비스는 출생 년도에 대한 정보를 별도로 수집하지 않으므로, 클래스 구성원의 연령이 만14세 미만인지 여부를 판단할 수 없습니다. 클래스 관리자는 만 14세 미만의 아동에게 계정을 발급할 때, 아동의 법정 대리인으로부터 ‘서비스 이용에 따른 개인정보 수집이용(제3자 제공)의 동의’를 받아야 합니다.
제15조 회원의 의무 
1. 회원은 본 약관의 규정, 회사의 이용정책, 이용 안내사항 및 관련 법령 등을 준수하여야 하며, 기타 회사 업무에 방해되는 행위를 하여서는 안 됩니다.
2. 회원은 회사에서 제공받은 모든 콘텐츠를 회사의 사전 동의 없이 복제, 판매, 편집, 전시, 배포, 방송 등 저작권을 침해하는 일체의 행위를 해서는 안 됩니다.
3. 회원은 다음 각호의 행위를 하여서는 안 됩니다.
① 가입 신청 또는 회원 정보 변경 시 허위 내용을 등록하는 행위
② 타인의 정보를 수집, 저장, 공개하거나 도용하여 부정하게 사용하는 행위
③ 비정상적인 가입을 시도하거나 서비스 제공을 방해하는 행위
④ 회사와 제3자의 저작권 등 지식재산권을 침해하는 행위
⑤ 서비스의 이용 권한, 기타 이용 계약상 지위를 타인에게 양도, 증여하는 행위
⑥ 회사의 동의 없이 영리를 목적으로 서비스를 사용하는 행위
⑦ 회사의 운영자, 임직원, 회사를 사칭하거나 관련 정보를 도용하는 행위
⑧ 회사 또는 제3자를 희롱하거나, 위협하거나 명예를 훼손시키는 행위
⑨ 고객센터 문의 시 욕설, 폭언, 성희롱, 반복적인 민원을 통해 업무를 방해하는 행위
⑩현행 법령에 위반되는 불법적인 행위
4. 회사는 회원이 제3항에서 금지한 행위를 하는 경우, 위반 행위의 경중에 따라 서비스 이용 제한, 수사기관에의 고발 조치 등 합당한 조처를 할 수 있습니다.
5. 회원은 본 서비스를 이용하는 모든 행위 및 그 결과에 대해 모든 책임을 집니다. 서비스 이용 시 발생하는 분쟁에 대해 해당 회원은 자신의 책임과 비용으로 회사를 면책시켜야 하며, 회사는 분쟁에 대하여 회사의 고의 또는 중과실이 없는 한 책임을 부담하지 않습니다.
6. 서비스는 출생 연도에 대한 정보를 별도로 수집하지 않고 있어 클래스 구성원의 연령이 만14세 미만인지 여부를 판단할 수 없습니다. 클래스 관리자는 만 14세 미만의 아동에게 계정을 발급할 때, 아동의 법정 대리인으로부터 ‘서비스 이용에 따른 개인정보 수집이용(제3자 제공)의 동의’를 받아야 합니다.
7. 회사는 회원이 본 조에서 규정한 의무를 위반하여 발생한 모든 문제에 대해서는 고의 또는 중대한 과실이 없는 한 책임을 부담하지 않습니다.
제16조 정보의 제공 및 광고의 게재
1. 회사는 서비스 운영과 관련하여 회원이 입력한 정보를 활용하여 광고를 게재할 수 있습니다. 
2. 회사는 회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보를 서비스 내 콘텐츠 화면, 공지사항 등의 방법으로 회원에게 제공할 수 있고, 회원의 사전 동의를 얻은 경우 이메일 등의 방법으로 제공할 수 있습니다. 다만, 회원은 거래 관련 정보 및 고객 문의에 대한 답변 등을 제외한 정보의 제공 및 광고는 언제든지 수신 거절을 할 수 있습니다.
3. 회사는 서비스상에 게재되어 있거나 서비스를 통한 제3의 판촉 활동에 회원이 참여하거나 교신 또는 거래함으로써 발생하는 손실과 손해에 대해 책임을 지지 않습니다. 다만, 회사의 고의 또는 중과실이 있는 경우에는 그러하지 아니합니다.
제17조 저작권 정책
1. 회사가 작성한 저작물에 대한 저작권 및 기타 지식재산권은 회사에 있습니다.
2. 본 서비스의 특성상 회원이 서비스 내에 학습한 결과물 등은 작성자 본인 및 법정대리인, 해당 콘텐츠를 공유한 회원 및 회원의 법정 대리인에게 공유될 수 있습니다. 공유 받은 회원 또는 법정 대리인은 결과물 열람 외 다른 목적으로 해당 결과물을 사용할 수 없습니다.
3. 회사는 회원사가 회사를 이용한다는 사실, 회원사의 상호, 상표, 회원사의 브랜드 이미지 로고(CI 및 BI)를 회사의 사이트 및 홍보자료로 수정하여 사용하고 공개할 수 있습니다. 다만, 회원사가 상호, 상표 등에 대한 삭제 또는 사용중지를 요청하면 회사는 관련 법률에 따라 보존해야 하는 사항을 제외하고 즉시 삭제 또는 사용을 중지합니다.
회사의 사용권은 아이스크림미디어 서비스를 운영하는 동안에만 유효합니다.
사용권에 대해 철회하고자 하는 경우 고객센터로 문의하여 철회할 수 있습니다.
제18조 권리의 귀속
1. 회사가 작성하고 제공하는 모든 콘텐츠에 대한 저작권은 회사에 있습니다.
2. 회사는 회원과 별도로 서면 계약을 체결하여 서비스의 브랜드 특성을 이용할 수 있는 명시적인 권리를 부여하지 아니하는 한, 회원에게 회사 또는 서비스의 상호, 상표, 로고, 도메인 네임 및 기타 식별력 있는 브랜드 특성을 이용할 수 있는 권리를 부여하지 않습니다.
3. 회사는 서비스와 관련하여 회사가 정한 이용 조건에 따라 회원에게 계정, 아이디, 콘텐츠 등을 이용할 수 있는 이용권만을 부여하며, 회원은 이를 양도, 판매, 담보 제공 등의 처분 행위를 할 수 없습니다.
4. 회사는 서비스 정책 또는 회사가 운영하는 서비스 간 통합 등의 사유로 게시물의 게재 위치를 변경 또는 이전할 수 있습니다. 

제4장 유료 서비스 이용 및 결제
제19조 유료 서비스의 이용
1. 회사는 아이스크림 수학 교과서 사용 회원에게 기본적으로 무료로 서비스를 제공하고 있으나, 유료 기능과 같은 일부 서비스와 대상, 시간 등 정책에 따라 유료로 제공할 수 있습니다.
2. 회사가 제공하는 유료서비스를 이용하기 위해서는 이용권을 구매해야 합니다.
3. 회사는 회원의 유료 서비스 이용 신청이 있는 경우, 해당 회원에게 이용 신청의 내용을 통지합니다.
4. 회사는 다음 각호와 같이 유료 서비스를 제공하며, 회사의 영업상 필요에 따라 상당한 이유가 있는 경우 회원에게 사전통지한 후 서비스 내용을 추가하거나 변경할 수 있습니다.
① 학교 판매 기간만료 유료서비스: 선생님이 클래스에 해당하는 학생 수에 따라 이용권을 구매하여 이용 가능 기간이 만료되는 시점에 유료 서비스의 이용이 종료되는 서비스를 의미합니다.
② 개인 판매 기간만료 유료서비스: 회사가 제공하는 유료 서비스를 회원이 법정대리인 동의를 통해 직접 결제하여 이용 가능 기간이 만료되는 시점에 유료 서비스의 이용이 종료되는 서비스를 의미합니다.
5. 서비스 이용요금에 관한 상세 내역은 약관에 명시되지 않고 서비스 이용요금안내 등 회사가 별도로 공지하는 서비스 이용요금 정책에 따릅니다.
6. 회사의 유료 서비스의 이용 가능 기기 및 이용에 필요한 최소한의 기술 사양은 권장 사양 정보에 따릅니다.
7. 회사는 유료 서비스를 제공함에 있어 유료 서비스의 취소 및 환불의 조건과 절차에 관한 사항을 제공합니다.


제20조 유료서비스 결제
1. 유료 서비스에서 사용할 수 있는 결제 수단은 다음 각호와 같습니다.
① 애플 및 구글 IAP(In-App-Purchase)
② 직불 카드, 신용카드 등의 각종 카드 결제
③ 기타 전자적 지급 방법에 의한 대금 지급 등
2. 회원은 유료 서비스 이용을 위하여 해당 서비스에 결제 수단 및 결제 정보를 입력하고 이를 저장할 수 있습니다. 저장된 결제 수단 및 결제 정보는 다음 결제 시에 사용됩니다.
3. 회원은 타인의 결제 수단을 임의로 사용하여서는 안됩니다. 타인의 결제 수단을 임의 사용함으로써 발생하는 회사, 결제 수단의 적법한 소유자, 기타 해당 결제와 관련된 제3자의 손실이나 손해에 대한 책임은 회사의 고의 또는 중과실이 없는 한 회원에게 있습니다. 
4. 유료 서비스 이용을 위한 결제와 관련하여 회원이 입력한 정보와 관련하여 발생한 문제에 대한 책임은 회사의 고의 또는 중과실이 없는 한 회원이 부담하여야 합니다.
5. 위탁 판매 유료 서비스의 경우 위탁자를 통해 판매되므로 그에 따른 결제 방법에 따릅니다.
6. 회원은 유료 서비스 이용요금 결제 시 정당하고, 적법한 사용 권한을 가지고 있는 결제 수단을 사용하여야 하며, 회사는 그 여부를 확인할 수 있습니다. 또한 회사는 회원이 사용한 결제 수단의 적법성 등에 관해 확인이 완료될 때까지 거래 진행을 중지하거나 해당 거래를 취소할 수 있습니다.
7. 애플 및 구글 IAP(In-App-Purchase) 결제를 이용한 경우 해당 업체의 결제 정책을 따르며, 각 사의 정책에 따라 수수료를 감안하여 다른 가격으로 판매될 수 있습니다.

제21조 유료 서비스 이용 계약의 성립
1. 본 약관에 동의한 회원은 유료서비스에 대한 이용 신청을 하고, 회사가 이를 승낙함(유로서비스의 구매/결제완료 등의 표시가 회원에게 절차상 표시된 시점)으로써, 유료서비스 이용 계약은 성립합니다.
2. 회사는 회원이 유료서비스를 결제하기 전 다음 각 호의 사항에 관하여 회원이 정확하게 이해하고 실수 또는 착오 없이 거래할 수 있도록 구매 페이지를 통해 정보를 제공합니다.
① 유료 서비스의 내용, 가격, 이용기간, 이용방법
② 이용권 취소 및 해지 조건 및 방법
③ 환불에 관한 사항
3. 회사의 승낙의 의사표시에는 회원의 이용신청에 대한 확인 및 서비스제공 가능여부, 이용신청의 정정, 취소 등에 관한 정보 등을 포함합니다.
4. 회사는 아래 각호에서 정하는 사유가 발생하는 경우, 유료서비스 이용신청을 승낙하지 않거나 승낙을 유보할 수 있습니다.
① 실명이 아니거나 타인이 명의를 이용한 경우
② 허위의 정보를 기재하거나, 회사가 요구하는 내용을 기재하지 않은 경우
③ 미성년자가 청소년 보호법에 의해서 이용이 금지되는 유료 서비스를 이용하고자 하는 경우
④ 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우
⑤ 무료 체험 기간 또는 서비스 이용 유예 기간 등 회사에서 제공하는 혜택을 남용하기 위한 재가입/재구매라고 판단되는 경우
⑥ 기타 회원의 귀책 사유로 승인이 불가능하다고 판단되는 경우
5. 회원은 유료서비스 이용 신청시 필요한 회원 정보를 정확하게 기재하여야 하며, 해당 사항이 변경될 경우 지체없이 회사가 정한 절차에 따라 변경사항을 고지하고, 회사는 지체없이 변경사항을 반영하여야 합니다. 
6. 회사는 결제 이행을 위하여 꼭 필요한 회원의 개인정보(신용정보 등 포함)를 추가로 요구할 수 있으며, 회원은 회사가 요구하는 개인정보를 정확하게 제공하여야 합니다.

제23조 유료 서비스의 취소 및 환불
1. 회사의 본 약관상 환불 규정 등은 ‘약관의 규제에 관한 법률’, ‘온라인 디지털 콘텐츠 산업 발전법’, ‘전자상거래 등에서의 소비자보호에 관한 법률’을 반영합니다.
2. 전 항의 관계법령에 따른 소비자의 청약철회가 불가능한 경우로는, 청약철회가 불가능한 콘텐츠에 대한 사실을 표시하항에 포함한 경우, 한시적 또는 일부 이용 등의 방법을 제공한 경우입니다.
3. 회사는 부득이한 사정으로 유료서비스를 지속할 수 없을 경우에 이용자가 이용요금에 대해 환불을 요청할 때, 각 결제 수단에 따른 환불 방식에 따라 환불 조치합니다. 단, 이용료 환불 시 회사가 규정한 환불 정책에 따릅니다.
4. 유료회원이 유료서비스 이용신청을 취소하고자 하는 경우에는 취소 사유를 명기하여 본인이 직접 회사가 정한 방법으로 취소 신청을 할 수 있습니다. 단, 환불 신청은 회사가 규정한 대상 및 신청기간에 따릅니다.
5. 취소 또는 환불 신청을 받은 회사는 각 서비스의 정책이 규정한 제비용을 제외하고, 취소 또는 환불 신청에 응할 수 있습니다. 단 회사는 다음 각 호에 해당하는 경우 이용자의 환불신청을 승낙하지 아니할 수 있습니다.
① 이용자의 실수로 서비스를 이용하지 못한 경우
② 서비스에서 탈퇴한 후 환불을 요구할 경우
③ 기타 정당한 환불사유로 판명되지 않은 경우
6. 회사는 회원이 프로모션 등을 통해 무료 및 무상으로 취득하는 등 회원이 직접 비용을 지불하지 아니하고 이용하는 유료서비스에 대하여 회원에게 유료서비스 결제 대금을 환불할 의무를 부담하지 않습니다.
7. 회사가 본 약관 제 8조(회원정보의 변경 및 탈퇴)에 따라 계약을 해지하거나 서비스 이용을 제한한 후 환불해야 할 금액이 있을 경우에는 본 조에서 정한 환불 기준을 적용하여 공제하고 환불합니다. 다만, 이 경우 회원은 해당 조치에 대하여 회사가 정한 절차에 따라 이의 신청을 할 수 있으며, 회사는 정당하다고 인정하는 경우 즉시 서비스 이용재개를 할 수 있습니다.
8. 유료서비스를 이용 중인 회원이 회원가입 계약을 해지하는 경우 해당 유료서비스는 즉시 해지되며, 환불할 경우 회사는 이용기간에 따른 서비스 이용료와 금융수수료를 공제하고 남은 금액을 환불하게 됩니다.
9. 회사는 회원의 환불 요청이 있을 시, 금융 수수료 및 이용료(서비스 이용한 경우) 를 공제하고 환불하는 것을 원칙으로 하며, 환불 금액 산정 기준은 아래와 같습니다.
1) 환불 금액 산정 기준
① 결제일로부터 7일이 지나기 전 신청된 건의 환불 금액
a.\t신용카드: 전액 환불 (카드 결제 취소 처리)
b.\t실시간 계좌이체: 전액 환불 (계좌이체 취소 처리)
c.\t가상계좌: 전액 환불(환불 요청하신 계좌로 환불 금액 입금 처리)
② 결제일로부터 7일이 경과한 후, 신청된 건의 환불 금액
총 결제금액 – 취소 수수료(총 결제 금액의 10%)-환불 기간 산정 범위에 따른 이용료 (환불 요청하신 계좌로 환불 금액 입금 처리)
2) 환불 기간 산정 범위에 따른 이용료
① 이용권 구입 시 제공되는 할인 혜택은 장기적으로 계속 이용하는 유료회원에 대한 혜택으로, 중도 해지시 환불 금액은 1개월 이용 요금을 기준으로 계산하여 환불 금액을 산정합니다.
② 차감하는 금액이 환불 금액을 초과할 경우 환불이 불가합니다.
10. 환불 신청에 따른 처리 소요시간은 결제 수단에 따라 수일에서 수개월이 소요될 수 있으며, 이용자는 이에 대해 회사의 고의적 지연이 아닌 한 책임을 물을 수 없습니다.
11. 네이버 스마트스토어, 애플 및 구글 IAP(In-App-Purchase)를 통한 결제 취소 또는 환불의 경우 해당 플랫폼의 환불 정책에 따릅니다.


제24조 이용권의 이용
1. 회사는 서비스를 이용할 수 있는 권리가 담긴 이용권을 판매합니다. 
2. 아이스크림 회원은 학교 판매 기간만료 유료서비스 신청시 학생 수에 따라 이용권을 구매하여, 다른 회원에게 이용권을 전송 및 등록하고 사용할 수 있습니다.
① 이용권 관리자는 이용권을 구매하여 특정 회원에게 사이트 내 쪽지 등을 통하여 전송할 수 있습니다. 이때, 이용권 결제자는 다른 회원에게 이용권을 전송하기 위하여 같은 학교로 가입되어 있는 회원의 정보에 접근할 수 있습니다.


② 수신자를 잘못 지정하여 이용권이 오발송된 후 수신자에 의해 이용권이 수령된 경우 회사에서는 오발송에 대해 책임지지 않습니다.
③ 이용권 결제자는 이용권 수신자를 대신하여 환불 또는 취소 요청을 할 수 있습니다.
단, 이용권 결제자와 이용권 수신자는 같은 학교 소속임을 확인할 수 있어야 합니다.
④ 이용권 수신자가 해당 이용권을 등록한 이후에 회원 탈퇴 시 이용권은 자동으로 소멸되며, 회사는 탈퇴 회원 및 관리자 회원에게 해당 이용권에 대한 보상을 하지 않습니다. 또한 탈퇴 후 재가입하더라도 소멸된 이용권은 복구되지 않습니다.
⑤ 이용권 수신자는 이용권을 직접 환불 또는 취소할 수 없으며, 이용권 결제자를 통해 진행하여야 합니다.
3. AI아크수학 회원은 개인 판매 기간만료 유료서비스 신청시 이용권을 네이버스토어 및 애플 및 구글 IAP(In-App-Purchase)를 통해 구매할 수 있습니다. 단, 법정대리인 동의 없이 구매할 수 없으며, 이 경우 회사에서는 결제 수단을 사용하여 구매한 모든 내역에 대한 책임을 지지 않습니다.
4. 회원이 다른 회원과 이용권을 유상으로 거래할 수 없습니다.
5. 회원이 부정한 목적 및 방법으로 이용권을 전송하거나 이용한 사실이 확인될 경우 회사는 회원의 이용권 회수, ID 삭제 등 필요한 조치를 취할 수 있습니다.




제5장 서비스 이용계약의 해지 및 취소
제25조 이용 계약 해지 및 취소
1. 회원이 본 약관을 위반하거나 서비스 운영에 지장을 초래하는 경우, 회사는 서비스 이용을 제한하거나 계약을 해지할 수 있습니다. 다만, 본 약관 및 관계법령에 위반되지 않는 범위 내에서 회사의 각 사이트마다 달리 적용할 수 있습니다.
2. 회원이 탈퇴를 요청하는 경우, 즉시 탈퇴 처리가 이루어지며, 관련 데이터는 일정 기간 후 삭제됩니다.
3. 회원은 탈퇴하는 경우 기존에 구매한 유료 서비스 이용이 불가합니다. 이에 대하여 회사가 본 사항을 안내하였음에도 불구하고 회원이 탈퇴한 경우 회사는 이에 대한 책임을 부담하지 않습니다.
4. 회원 탈퇴 후에는 본인의 이용 내역 확인이 불가합니다.

제26조 이용 제한
1. 회사는 회원이 본 약관 및 운영정책을 위반하거나 서비스의 정상적인 운영을 방해한 경우, 경고, 일시 정지, 영구 이용정지 등으로 서비스 이용을 단계적으로 제한할 수 있습니다. 
2. 회원이 불법 프로그램의 제공 및 운영 방해, 불법 통신 및 해킹, 악성프로그램의 배포, 접속 권한 초과 행위 등과 같이 관련법령을 위반한 경우, 회사는 해당 회원에 대해 즉시 영구 이용정지 처분을 할 수 있습니다. 영구 이용정지 시, 회원이 서비스 이용을 통해 획득한 혜택은 모두 소멸되며, 회사는 이에 대해 별도로 보상하지 않습니다. 관련 제한의 조건 및 세부 내용은 회사의 운영정책을 따릅니다.
3. 서비스 이용을 제한하거나 계약을 해지하는 경우, 회사는 통지 방식에 따라 해당 사실을 알리며 이때 회원은 회사가 정한 절차에 따라 이의 신청을 할 수 있습니다. 이의가 정당하다고 회사가 인정하는 경우 회사는 즉시 서비스의 이용을 재개합니다.


제 6장 기타
제27조 학습데이터 수집 및 활용
1. 회원이 서비스를 이용하는 동안 입력되는 학습 데이터는 인공지능 윤리기준을 벗어나지 않는 범위에서 활용되며, 데이터 수집과 활용의 전 과장에서 데이터 편향성이 최소화되도록 데이터 품질과 위험을 관리합니다.
2. 학습데이터는 학생 회원 및 AI아크수학 회원의 교육활동을 지원합니다.
3. 학습데이터 활용 시 모든 데이터는 가명으로 처리됩니다.
제28조 손해배상
1. 회사는 약관, 서비스 이용 방법 및 이용 기준을 준수하지 않는 등 회원의 귀책 사유로 인한 서비스 이용의 장애에 대한 책임을 지지 않습니다.
2. 회사는 서비스의 일부 또는 전부를 회사의 정책, 운영상의 긴급한 사유로 수정, 중단, 변경할 수 있으며 이에 대하여 관련 법령에 별도 규정이 있지 아니하는 한 별도의 보상을 하지 않습니다.
3. 회사는 관련 법령의 변경, 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임을 부담하지 않습니다.
4. 회사는 무료로 제공되는 서비스의 완전성, 무결성, 적절성 등을 보증하지 않습니다.
회사는 회원이 서비스를 이용하여 기대하는 효용을 얻지 못한 것에 대하여 책임을 지지 않으며 서비스에 대한 취사선택 또는 이용으로 발생하는 손해 등에 대해서도 책임이 면제됩니다.
5. 본 조는 회사가 고의 또는 중대한 과실이 있는 경우에는 적용하지 아니합니다.

제29조 분쟁의 해결
1. 회사와 회원은 서비스와 관련하여 발생한 분쟁을 원만하게 해결하도록 노력을 다해야 합니다.
2. 회사와 회원 간에 발생한 분쟁에 관한 소송은 회사의 본사 소재지 관할 법원에서 진행합니다. 다만, 회원의 요청에 의해 회원의 주소지를 관할하는 지방법원에서 진행할 수 있습니다.
3. 회사와 회원 간에 제기된 소송은 대한민국 법을 준거법으로 하며, 발생한 분쟁에 대해서는 민사소송법이 정한 절차를 따릅니다.

부칙
본 약관은 2025년 2월 17일자부터 시행합니다.
`;
  const personalInformationProvisionConsentText = `1. 제공받는 자: ㈜매쓰마스터

2. 개인정보 제공 목적
- 회원관리
- 서비스 제공 및 운영
- 마케팅 및 서비스 개선
- 통합 아이디 서비스 제공

3. 개인정보 제공 항목
- 선생님( 아이스크림 통합회원) : (필수) 아이디, 비밀번호, 닉네임, 이름, 이메일, 학교명, (선택) 휴대폰번호, 학년 정보
- 학생 (일반 회원) 가입 시 : (필수) 아이디, 비밀번호, 이름, 법정대리인 정보(이름, 휴대폰번호 또는 이메일 주소), (선택) 닉네임
- 자동수집 · 저장 항목:  IP, 쿠키, 기기정보, 브라우저 종류, 접속 로그 방문 일시, 서비스 이용 기록, 불량 이용 기록, 결제 기록

4. 개인정보 제공 시 보유 ·  이용 기간
- 회원 탈퇴 시 까지
단, 이용자의 개인정보 보유기간에 대해 별도의 동의를 얻은 경우 또는 관련 법령에 따른 정보 보유 사유에 해당하는 경우 정해진 기간 까지

개인정보 제공 동의에 거부할 수 있으며, 거부 시 아크수학 서비스 이용이 어려울 수 있습니다.
`;
  const marketingConsentText = `AI아크수학의 운영사, ㈜아이스크림미디어와 ㈜매쓰마스터는 AI 아크수학 서비스와 관련된 이벤트 및 혜택 안내 등을 목적으로 카카오 알림톡, 문자메시지(SMS)를 통해 마케팅 정보를 발송하고자 합니다.
마케팅 정보 활용에 동의하지 않으셔도 서비스 이용에는 영향이 없으며, 동의를 철회할 수 있습니다. 

회원가입, 주문배송 등 관련 안내를 위한 카카오 알림톡, 문자메시지 등은 마케팅 활용 동의와 관계없이 모든 회원에게 발송됩니다.

1. 수집 및 이용 목적
상품 및 서비스 안내, 할인 혜택(프로모션), 이벤트 행사 정보 제공

2. 수집항목
이름, 휴대폰번호, 이메일

3. 보유 및 이용기간
회원탈퇴 또는 고객 삭제요청시까지, 단 관련 법령에 따라 일정기간 동안 보존이 필요한 경우 해당 기간 동안 보관 후 파기

위의 개인정보 수집 및 마케팅 활용에 대한 동의를 거부할 권리가 있습니다. 그러나 동의를 거부할 경우 프로모션 및 이벤트 정보를 받을 수 없습니다.`;
  const preStyle = {
    whiteSpace: 'pre-wrap',     // 줄바꿈을 유지하면서 부모의 가로값에 맞게 텍스트 감싸기
    wordWrap: 'break-word',      // 긴 단어가 있을 경우 단어 단위로 줄바꿈
    overflow: 'auto',            // 내용이 넘칠 경우 스크롤 생성
    padding: '10px',             // 내부 여백(optional)
    border: '1px solid #ccc'     // 테두리(optional)
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [teacher, setTeacher] = useState({});
  const [school, setSchool] = useState({});
  const [lessonInfo, setLessonInfo] = useState(null);
  const [classes, setClasses] = useState([]);
  const [classDetails, setClassDetails] = useState([]);
  const [showTermsOfServiceModal, setShowTermsOfServiceModal] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [termsType, setTermsType] = useState('');
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showInquiryCreationModal, setShowInquiryCreationModal] = useState(false);
  const [showInquiryDetailModal, setShowInquiryDetailModal] = useState(false);
  const [inquiries, setInquiries] = useState([]);
  const [value, setValue] = useState({});

  const openInquiryCreationModal = () => {
    setShowInquiryCreationModal(true);
  };

  const openInquiryDetailModal = (value) => {
    setValue(value);
    setShowInquiryDetailModal(true);
  };

  const closeThing = () => {
    if (showInquiryCreationModal && showInquiryDetailModal) {
      setShowInquiryDetailModal(false);
    } else {
      setShowInquiryCreationModal(false);
    }
  };

  useKeyPress(27, closeThing);

  const encodedImTeacherLoginIdParam = searchParams.get('mId') || '';
  const imLessonIdParam = searchParams.get('lessonId') || '';

  const openTermsModal = (termsType) => {
    setTermsType(termsType);
    setShowTermsModal(true);
  };

  const fetchTeacherDetails = async (body) => {
    const data = await getTeacherDetails(body);
    if (data?.skip) return;
    return data;
  };

  const fetchInquiries = async (body) => {
    const data = await getInquiries(body);
    if (data?.skip) return;
    return data;
  };

  const agreeTermsOfService = async () => {
    if (!checks.required1 || !checks.required2) {
      checkToast('필수 약관을 동의해 주세요.');
      return;
    }
    const body = {
      teacherId: teacher?.teacherId,
      isTermsOfServiceAgreed: true,
      isMarketingAgreed: checks.optional,
    };
    const data = await updateTeacher(body);
    if (data?.skip) return;
    setShowTermsOfServiceModal(false);
  };

  const startReload = () => {
    window.location.reload();
  };

  // 초기 상태: 전체 체크박스와 개별 체크박스 상태 저장
  const [checks, setChecks] = useState({
    selectAll: false,
    required1: false,
    required2: false,
    optional: false,
  });

  // '전체 체크' 박스 핸들러: 클릭 시 3개 체크박스 모두 체크/해제
  const handleSelectAllChange = (e) => {
    const checked = e.target.checked;
    setChecks({
      selectAll: checked,
      required1: checked,
      required2: checked,
      optional: checked,
    });
  };

  // 개별 체크박스 핸들러
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    // 기존 상태를 복사하여 해당 체크박스의 상태만 변경
    const newChecks = {
      ...checks,
      [name]: checked,
    };
    // 모든 개별 체크박스(필수 2 + 선택 1)가 체크되었으면 전체 체크도 true로 업데이트
    newChecks.selectAll = newChecks.required1 && newChecks.required2 && newChecks.optional;
    setChecks(newChecks);
  };

  useEffect(() => {
    if (showTermsModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [showTermsModal]);

  useEffect(() => {
    if (showTermsOfServiceModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [showTermsOfServiceModal]);

  useEffect(() => {
    if (showInquiryCreationModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [showInquiryCreationModal]);

  useEffect(() => {
    if (encodedImTeacherLoginIdParam && imLessonIdParam) {
      // 세션에 데이터 저장
      setSessionData('encodedImTeacherLoginId', encodedImTeacherLoginIdParam);
      // 주소에서 mId 쿼리 파라미터 삭제
      const params = new URLSearchParams(searchParams);
      params.delete('mId');
      setSearchParams(params);
    } else {
      let canLoginProceed = false;
      let encodedImTeacherLoginId;
      let imLessonId;
      // 세션에서 데이터 조회
      let sessionEncodedImTeacherLoginId = getSessionData('encodedImTeacherLoginId');
      if (sessionEncodedImTeacherLoginId && imLessonIdParam) {
        encodedImTeacherLoginId = sessionEncodedImTeacherLoginId;
        imLessonId = imLessonIdParam;
        canLoginProceed = true;
      }
      if (canLoginProceed) {
        let body = {
          encodedImTeacherLoginId: encodedImTeacherLoginId,
          imLessonId: imLessonId,
        };
        fetchTeacherDetails(body)
          .then((data) => {
            setIsLoading(true);
            if (data) {
              // console.log('getTeacherDetails response: ', data);
              setTeacher(data.teacher);
              setSchool(data.school);
              setLessonInfo(data.lessonInfo);
              setClasses(data.classes);
              setClassDetails(data.classDetails);
              if (data.teacher.isTermsOfServiceAgreed) {
                setShowTermsOfServiceModal(false);
              } else {
                setShowTermsOfServiceModal(true);
              }
              body = {
                teacherId: data.teacher.teacherId,
              };
              fetchInquiries(body)
                .then((data2) => {
                  // console.log('getInquiries response: ', data2);
                  setInquiries(data2.inquiries);
                });
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        // If encodedImTeacherLoginIdParam and imLessonIdParam do not exist, redirect to the empty page.
        navigate(`/empty`);
      }
    }
  }, [encodedImTeacherLoginIdParam, imLessonIdParam]);

  return (
    <div className={'min-w-[1340px]'}>
      <LeftBanner />
      <InquiryCreationModal showInquiryCreationModal={showInquiryCreationModal} setShowInquiryCreationModal={setShowInquiryCreationModal} teacherId={teacher?.teacherId} inquiries={inquiries}
                            setInquiries={setInquiries} openInquiryDetailModal={openInquiryDetailModal} zIndex={100}/>
      <InquiryDetailModal showInquiryDetailModal={showInquiryDetailModal} setShowInquiryDetailModal={setShowInquiryDetailModal} value={value} zIndex={200}/>
      <InquiryIcon className={'fixed bottom-[20px] right-[20px] z-[10] cursor-pointer'} onClick={openInquiryCreationModal}/>
      <TermsModal showTermsModal={showTermsModal} setShowTermsModal={setShowTermsModal} termsType={termsType}/>
      {!isLoading && showTermsOfServiceModal ? (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[1010]">
          <div className={'bg-white rounded-[20px] max-h-[90vh] overflow-auto'}>
            <div className={'min-w-[936px] w-[936px] px-[80px]'}>
              <div className={'mt-[30px] mb-[30px]'}>
                <div className={'text-[36px] font-[pd-eb] flex justify-center items-center'}>
                  약관 동의
                </div>
                <div className={'mt-[20px] text-center'}>
                  서비스를 이용하기 위해서는 아래 약관에 대한 동의가 필요합니다.
                </div>
                <div className={'mt-[20px]'}>
                  <div>
                    <label className={'flex items-center cursor-pointer select-none'}>
                      <input
                        type="checkbox"
                        name="selectAll"
                        checked={checks.selectAll}
                        onChange={handleSelectAllChange}
                        className={'w-[32px] h-[32px]'}
                      />
                      <div className={'text-[24px] font-[pd-eb] ml-[10px]'}>
                        전체 동의
                      </div>
                    </label>
                  </div>
                  <div className={'mt-[10px]'}>
                    <label className={'flex items-center cursor-pointer select-none'}>
                      <input
                        type="checkbox"
                        name="required1"
                        checked={checks.required1}
                        onChange={handleCheckboxChange}
                        className={'w-[18px] h-[18px]'}
                      />
                      <div className={'w-[500px] ml-[6px]'}>
                        AI아크수학 이용약관 동의 <span className={'text-[#FC5955]'}>(필수)</span>
                      </div>
                    </label>
                    <pre style={preStyle} className={'h-[130px]'}>{termsOfServiceConsentText}</pre>
                  </div>
                  <div className={'mt-[10px]'}>
                    <label className={'flex items-center cursor-pointer select-none'}>
                      <input
                        type="checkbox"
                        name="required2"
                        checked={checks.required2}
                        onChange={handleCheckboxChange}
                        className={'w-[18px] h-[18px]'}
                      />
                      <div className={'w-[500px] ml-[6px]'}>
                        개인정보 제공 동의 <span className={'text-[#FC5955]'}>(필수)</span>
                      </div>
                    </label>
                    <pre style={preStyle} className={'h-[130px]'}>{personalInformationProvisionConsentText}</pre>
                  </div>
                  <div className={'mt-[10px]'}>
                    <label className={'flex items-center cursor-pointer select-none'}>
                      <input
                        type="checkbox"
                        name="optional"
                        checked={checks.optional}
                        onChange={handleCheckboxChange}
                        className={'w-[18px] h-[18px]'}
                      />
                      <div className={'w-[500px] ml-[6px]'}>
                        마케팅 활용 동의 <span className={'text-[#FC5955]'}>(선택)</span>
                      </div>
                    </label>
                    <pre style={preStyle} className={'h-[130px]'}>{marketingConsentText}</pre>
                  </div>
                </div>
              </div>
              <div className={'mt-[30px] pb-[30px] flex justify-center items-center'}>
                <div className={'w-full h-[50px] border rounded-[100px] cursor-pointer select-none flex justify-center items-center font-[pd-b] bg-[#355BDF] text-[#ffffff]'}
                     onClick={() => agreeTermsOfService()}>동의함
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {/*<TopBar/>*/}
      <Content
        startReload={startReload}
        teacher={teacher}
        setTeacher={setTeacher}
        school={school}
        lessonInfo={lessonInfo}
        classes={classes}
        setClasses={setClasses}
        classDetails={classDetails}
        setClassDetails={setClassDetails}
      />
    </div>
  );
};

export default LessonPage;
