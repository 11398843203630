import { CSSTransition } from 'react-transition-group';
import { useEffect, useRef, useState } from 'react';
import './DraggableModal.css';

const DraggableModal = ({ show, setShow, children, zIndex = 1000, headerHeight = 50, title, titleStyle }) => {
  const modalRef = useRef(null);
  const overlayRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
  const [dragPosition, setDragPosition] = useState({ x: window.innerWidth / 2, y: window.innerHeight / 2 });

  useEffect(() => {
    if (show && modalRef.current) {
      setDragPosition({
        x: window.innerWidth / 2,
        y: window.innerHeight / 2,
      });
    }
  }, [show]); // 모달이 열릴 때만 실행

  const startDrag = (e) => {
    setIsDragging(true);
    setStartPosition({
      x: e.clientX - dragPosition.x,
      y: e.clientY - dragPosition.y,
    });
  };

  const onDrag = (e) => {
    if (!isDragging) return;
    setDragPosition({
      x: e.clientX - startPosition.x,
      y: e.clientY - startPosition.y,
    });
  };

  const stopDrag = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', onDrag);
      document.addEventListener('mouseup', stopDrag);
    } else {
      document.removeEventListener('mousemove', onDrag);
      document.removeEventListener('mouseup', stopDrag);
    }

    return () => {
      document.removeEventListener('mousemove', onDrag);
      document.removeEventListener('mouseup', stopDrag);
    };
  }, [isDragging, onDrag]);

  return (
    <>
      {/* 오버레이 */}
      <CSSTransition
        in={show}
        nodeRef={overlayRef}
        timeout={300}
        classNames={'overlay'}
        unmountOnExit
      >
        <div
          ref={overlayRef}
          className="modal-overlay"
          style={{
            zIndex: zIndex
          }}
          onClick={() => setShow(false)} // 오버레이 클릭 시 모달 닫기
        />
      </CSSTransition>

      {/* 모달 */}
      <CSSTransition
        in={show}
        nodeRef={modalRef}
        timeout={300}
        classNames={'modal'}
        unmountOnExit
      >
        <div
          ref={modalRef}
          className="modal-content"
          style={{
            zIndex: zIndex + 10,
            left: `${dragPosition.x}px`,
            top: `${dragPosition.y}px`,
          }}
          onClick={(e) => e.stopPropagation()} // 모달 내부 클릭 이벤트 중지
        >
          <div
            className="modal-header"
            style={{
              height: headerHeight,
              cursor: isDragging ? 'grabbing' : 'grab',
            }}
            onMouseDown={startDrag}
          >
            <div className={`w-full h-full flex justify-center items-center ${titleStyle}`}>
              {title}
            </div>
            <div onClick={() => setShow(false)} className="absolute right-[12px] top-[12px] select-none cursor-pointer p-[4px] rounded-lg hover:bg-gray-100">
              <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                <path d="M26.8666 9.1333L9.1333 26.8666" stroke="black" strokeWidth="2.95556" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M9.1333 9.1333L26.8666 26.8666" stroke="black" strokeWidth="2.95556" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </div>
          </div>
          <div className={'max-h-[70vh] overflow-auto'}>
            {children}
          </div>
        </div>
      </CSSTransition>
    </>
  );
};

export default DraggableModal;
