export const translateEnumValue = (enumCategory, value) => {
  const translationMap = {
    inquiryType: {
      SERVICE: '서비스 문의',
      ERROR: '에러 신고',
      PAYMENT: '구매 및 환불',
      OTHER: '기타',
    },
    inquiryStatus: {
      NEW: '신규',
      IN_PROGRESS: '처리 중',
      RESOLVED: '답변 완료',
      CLOSED: '닫힘',
    }
    // 다른 enum 카테고리도 여기에 추가할 수 있습니다.
    // 예: userRole: { ADMIN: '관리자', USER: '일반 사용자' }
  };
  const categoryMapping = translationMap[enumCategory];
  if (!categoryMapping) return value; // 해당 카테고리가 없으면 원래 값을 그대로 반환
  return categoryMapping[value] || value;
};
