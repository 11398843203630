import React from 'react';

/**
 * 주어진 문자열을 줄바꿈 문자(\n)를 기준으로 분리하여
 * 각 줄 사이에 <br/> 태그를 삽입한 React 요소 배열을 반환합니다.
 *
 * @param {string} text - 변환할 문자열
 * @returns {React.Fragment[]} - 줄바꿈이 적용된 React 요소 배열
 */
export function formatTextWithLineBreaks (text = '') {
  return text.split('\n').map((line, index, arr) => (
    <React.Fragment key={index}>
      {line}
      {index < arr.length - 1 && <br/>}
    </React.Fragment>
  ));
}
