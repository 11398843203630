import DraggableModal from '../../shared/components/DraggableModal';
import { Fragment } from 'react';
import { formatTextWithLineBreaks } from '../../utils/formatTextUtils';
import dayjs from 'dayjs';
import { translateEnumValue } from '../../utils/translateUtils';

const InquiryDetailModal = ({ showInquiryDetailModal, setShowInquiryDetailModal, value, zIndex }) => {
  return (
    <DraggableModal show={showInquiryDetailModal} setShow={setShowInquiryDetailModal} zIndex={zIndex} title={'지난 문의'} titleStyle={'font-[pd-eb] text-[36px] text-[#303947]'} headerHeight={97}>
      <div className={'w-[900px] px-[42px]'}>
        <div className={'border border-[#5E6169] h-[2px]'}></div>
        <div className={'flex h-[53px] items-center'}>
          <div className={'text-[18px] font-[pd-eb] text-[#50555f] ml-[12px]'}>유형</div>
          <div className={'ml-[105px] text-[17px] text-[#50555f]'}>
            {translateEnumValue('inquiryType', value.type)}
          </div>
        </div>
        <div className={'border border-[#E9E9E9] h-[1px]'}></div>
        <div className={'flex h-[53px] items-center'}>
          <div className={'text-[18px] font-[pd-eb] text-[#50555f] ml-[12px] whitespace-nowrap'}>제목</div>
          <div className={'ml-[105px] text-[17px] text-[#50555f] truncate'}>
            {value.title}
          </div>
        </div>
        <div className={'border border-[#5E6169] h-[2px]'}></div>
        <div className={'overflow-scroll'}>
          <div className={'mt-[33px]'}>
            <div className={'px-[10px]'}>
              <div className={'flex items-center h-[36px]'}>
                <div className={'w-[32px] h-[32px] rounded-full bg-[#a3c6ff] flex justify-center items-center text-[20px] font-[pd-eb]'}>
                  Q
                </div>
                <div className={'text-[24px] font-[pd-eb] ml-[8px]'}>
                  {dayjs(value.inquiredAt).format('YYYY-MM-DD HH:mm')}
                </div>
              </div>
              <div className={'mt-[8px] text-[20px] font-[pd-r]'}>
                {formatTextWithLineBreaks(value.content)}
              </div>
            </div>
          </div>
          <div className={'border border-[#E9E9E9] h-[1px] mt-[27px]'}></div>
          <div className={'mt-[33px] mb-[30px] px-[10px] text-[20px] font-[pd-r]'}>
            {value.answeredAt && value.answer ? (
              <Fragment>
                <div className={'flex items-center h-[36px]'}>
                  <div className={'w-[32px] h-[32px] rounded-full bg-[#FFBF00] flex justify-center items-center text-[20px] font-[pd-eb]'}>
                    A
                  </div>
                  <div className={'text-[24px] font-[pd-eb] ml-[8px]'}>
                    {dayjs(value.answeredAt).format('YYYY-MM-DD HH:mm')}
                  </div>
                </div>
                <div className={'mt-[8px]'}>
                  {formatTextWithLineBreaks(value.answer)}
                </div>
              </Fragment>
            ) : (
              <div>답변 대기 중...</div>
            )}
          </div>
        </div>
      </div>
    </DraggableModal>
  );
};

export default InquiryDetailModal;
