import HomeworkImage from '../../resources/images/homework.png'
import DraggableModal from '../../shared/components/DraggableModal'

const HomeworkModal = ({ showHomeworkModal, setShowHomeworkModal }) => {
  return (
    <DraggableModal show={showHomeworkModal} setShow={setShowHomeworkModal} headerHeight={70} titleStyle={'bg-[#bed6ff] rounded-t-[20px]'}>
      <div className={'w-[700px] h-[550px]'}>
        <img src={HomeworkImage} alt={'homework'} className={'w-full'}/>
        <div className={'flex justify-center mt-[30px]'}>
          <a target={'_blank'} href={'https://i-screammall.co.kr/goods/detail/10028021'}
             className={'w-[160px] h-[60px] bg-[#5a85ff] text-[#ffffff] rounded-full flex justify-center items-center text-[20px] font-[pd-b] cursor-pointer'}>상품 보기</a>
          <div onClick={() => setShowHomeworkModal(false)}
               className={'w-[160px] h-[60px] bg-[#D9D9D9] text-[#50555F] rounded-full flex justify-center items-center text-[20px] font-[pd-b] cursor-pointer ml-[40px]'}>닫기
          </div>
        </div>
      </div>
    </DraggableModal>
  )
}

export default HomeworkModal
