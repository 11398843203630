import axios from 'axios';
import { checkToast } from '../shared/components/Toast';
import packageJson from '../../package.json';

const req = axios.create({
  baseURL: process.env.REACT_APP_HOST,
  headers: {
    'Content-Type': 'application/json',
  },
});

req.interceptors.request.use(
  (config) => {
    config.headers['App-Version'] = packageJson.version;
    return config;
  },
  (error) => Promise.reject(error)
);

req.interceptors.response.use(
  (response) => {
    // 성공 응답 처리
    const { status, data } = response.data;
    if (status?.code === 'Success') {
      // 요청이 성공적일 경우 data 반환
      return data;
    } else {
      // 특정 상태 코드 처리
      if (status?.code === 'InvalidLearningTime') {
        checkToast('수업 시간이 아니에요!');
      } else if (status?.code === 'HomeworkAlreadyAssigned') {
        checkToast(`이미 숙제가 전송되었습니다.`);
      } else if (status?.code === 'LessonAlreadyAssigned') {
        checkToast('이미 스테이지가 전송되었습니다.');
      } else {
        alert(`[${status?.code}] ERROR: 오류가 발생했어요.\n아크수학으로 문의해 주세요.`);
      }
      return { skip: true }; // 스킵 플래그 추가
    }
  },
  (error) => {
    // 에러 응답 처리
    if (error.response) {
      const { timestamp, status, error: errorType, message, code } = error.response.data;
      console.error('API Error:', {
        timestamp,
        status,
        error: errorType,
        message,
        code,
      });

      // 사용자에게 알림
      alert(`Error (${status}): ${message || 'An error occurred.'}`);
    } else {
      console.error('Network Error:', error.message || 'Unable to reach server.');
      alert('Network Error: Unable to reach the server. Please check your connection.');
    }
    return { skip: true }; // 스킵 플래그 추가
  }
);

export { req };
