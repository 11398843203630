import { useEffect, useRef, useState } from 'react';
import { isOverflowing } from '../../utils/isOverflowing';

const LearningStatusPill = ({ value, bgColor }) => {
  const textRef = useRef(null);
  const [isOverflow, setIsOverflow] = useState(false);

  // 오버플로우 체크 로직을 별도의 함수로 분리하여 사용
  const checkOverflow = () => {
    const el = textRef.current;
    if (el) {
      setIsOverflow(isOverflowing(el));
    }
  };

  useEffect(() => {
    checkOverflow();
  }, [value]); // value가 바뀔 때마다 체크

  return (
    <div className={`w-[96px] h-[37px] flex items-center bg-[${bgColor}] rounded-full text-[#5E6169] text-[14px] font-[pd-b]`}>
      <span
        ref={textRef}
        className={`block w-full truncate ${isOverflow ? 'text-left' : 'text-center'}`}
      >
        {value.number} {value.name}
      </span>
    </div>
  );
};

export default LearningStatusPill;
