import DraggableModal from '../../shared/components/DraggableModal';
import { useEffect, useState } from 'react';
import { checkToast, successToast } from '../../shared/components/Toast';
import { createInquiry } from '../../network/Endpoint';
import { translateEnumValue } from '../../utils/translateUtils';
import dayjs from 'dayjs';

const InquiryCreationModal = ({ showInquiryCreationModal, setShowInquiryCreationModal, teacherId, inquiries, setInquiries, openInquiryDetailModal, zIndex }) => {
  const [formData, setFormData] = useState({
    title: '',
    type: '',
    content: '',
  });

  const handleFormData = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const submitInquiry = async (e) => {
    e.preventDefault();
    if (!formData.type) {
      checkToast('유형을 선택해 주세요!');
      return;
    } else if (!formData.title) {
      checkToast('제목을 입력해 주세요!');
      return;
    } else if (!formData.content) {
      checkToast('내용을 입력해 주세요!');
      return;
    }
    if (window.confirm('문의를 등록하시겠습니까?')) {
      const body = {
        type: formData.type,
        title: formData.title,
        content: formData.content,
        teacherId: teacherId,
      };
      const data = await createInquiry(body);
      if (data?.skip) return;
      // console.log(data);
      setInquiries((prev) => [data.inquiry, ...prev]);
      setFormData({
        type: '',
        title: '',
        content: '',
      });
      successToast('문의 등록 완료!');
    }
  };

  useEffect(() => {
    if (showInquiryCreationModal) {
      setFormData({
        type: '',
        title: '',
        content: '',
      });
    }
  }, [showInquiryCreationModal]);

  return (
    <DraggableModal show={showInquiryCreationModal} setShow={setShowInquiryCreationModal} zIndex={zIndex} title={'문의하기'} titleStyle={'font-[pd-eb] text-[36px] text-[#303947]'} headerHeight={97}>
      <div className={'w-[784px] px-[42px] pb-[30px]'}>
        <form onSubmit={submitInquiry}>
          <div className={'border border-[#5E6169] h-[2px]'}></div>
          <div className={'flex h-[53px] items-center'}>
            <div className={'text-[18px] font-[pd-eb] text-[#50555f] ml-[12px]'}>유형</div>
            <div className={'ml-[105px]'}>
              <select name={'type'} value={formData.type} onChange={handleFormData} className={'border border-[#868E9F] rounded-[5px] h-[37px]'}>
                <option value={''}>유형을 선택해 주세요.</option>
                <option value={'SERVICE'}>서비스 문의</option>
                <option value={'ERROR'}>에러 신고</option>
                <option value={'PAYMENT'}>구매 및 환불</option>
                <option value={'OTHER'}>기타</option>
              </select>
            </div>
          </div>
          <div className={'border border-[#E9E9E9] h-[1px]'}></div>
          <div className={'flex h-[53px] items-center'}>
            <div className={'text-[18px] font-[pd-eb] text-[#50555f] ml-[12px]'}>제목</div>
            <div className={'ml-[105px]'}>
              <input type={'text'} name={'title'} value={formData.title} onChange={handleFormData} className={'border border-[#868E9F] rounded-[5px] h-[37px] pl-[10px] w-[539px]'}/>
            </div>
          </div>
          <div className={'border border-[#5E6169] h-[2px]'}></div>
          <div className={'mt-[33px]'}>
            <textarea name={'content'} value={formData.content} onChange={handleFormData} className={'border border-[#868E9F] rounded-[5px] p-[10px] w-full h-[230px]'}></textarea>
          </div>
          <div className={'flex justify-center mt-[20px]'}>
            <button type={'button'} className={`cursor-pointer select-none w-[120px] h-[44px] rounded-full bg-[#bfbfbf] text-[#ffffff] text-[20px] font-[pd-b]`}
                    onClick={() => setShowInquiryCreationModal(false)}>취소
            </button>
            <button type={'submit'} className={`cursor-pointer select-none w-[120px] h-[44px] rounded-full bg-[#7494FF] text-[#ffffff] text-[20px] font-[pd-b] ml-[20px]`}>확인</button>
          </div>
        </form>
        <div className={'mt-[32px]'}>
          <table className="table-fixed border-separate border-spacing-0 w-full text-[#50555F]">
            <thead className={'text-[18px] font-[pd-eb]'}>
            <tr className="h-[55px]">
              <th className="sticky top-0 bg-[#fafbfb] border-b-[1px] w-[20%] border-t-[#5E6169] border-t-[2px]">No.</th>
              <th className="sticky top-0 bg-[#fafbfb] border-b-[1px] w-[30%] border-t-[#5E6169] border-t-[2px]">지난 문의</th>
              <th className="sticky top-0 bg-[#fafbfb] border-b-[1px] w-[30%] border-t-[#5E6169] border-t-[2px]">등록일</th>
              <th className="sticky top-0 bg-[#fafbfb] border-b-[1px] w-[20%] border-t-[#5E6169] border-t-[2px]">상태</th>
            </tr>
            </thead>
            <tbody className={'text-[16px] font-[pd-r]'}>
            {inquiries?.map((value, index) => (
              <tr key={index} onClick={() => openInquiryDetailModal(value)} className={'cursor-pointer h-[55px] text-center'}>
                <td className={'border-b-[1px]'}>{inquiries.length - index}</td>
                <td className={'border-b-[1px] truncate'}>{value.title}</td>
                <td className={'border-b-[1px]'}>{dayjs(value.inquiredAt).format('YYYY-MM-DD HH:mm')}</td>
                <td className={'border-b-[1px]'}>{translateEnumValue('inquiryStatus', value.status)}</td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      </div>
    </DraggableModal>
  );
};

export default InquiryCreationModal;
